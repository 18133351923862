/* --------------------------- 최상단 GNB --------------------------- */
.lytGnb {
  width: 100%;
  height: 80px;
  z-index: 100;
}
.lytGnb .gnbFixed {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #e5e5e5;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}
.lytGnb .gnbArea {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.lytGnb .gnbArea:after {
  content: "";
  display: block;
  clear: both;
}
.lytGnb .gnbLogo {
  width: 220px;
  height: 50px;
  margin-top: 15px;
  float: left;
}
.lytGnb .gnbLogo h1 {
  width: 100%;
  height: 100%;
  display: block;
}
.lytGnb .gnbLogo a {
  width: 100%;
  height: 100%;
  font-size: 0; /*background:url(/images/icon/img-logo-b_2.svg) center no-repeat; */
  display: block;
}
.lytGnb .gnbMenu {
  /* width: calc(100% - 720px); */
  padding-right: 20px;
  /* float: right; */
}
.lytGnb .gnbDepth01 {
  width: 100%;
  text-align: right;
}
.lytGnb .gnbDepth01 .depth01List {
  display: inline-block;
}
.lytGnb .gnbDepth01 .depth01Name {
  padding: 0 20px;
}
.lytGnb .gnbDepth01 .depth01Name > a {
  height: 80px;
  padding: 24px 0;
  font-family: NotoSansCJKkr;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: -0.38px;
  color: #000000;
  display: inline-block;
  position: relative;
}
.lytGnb .gnbDepth01 .depth01Name > a:hover:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #000;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
}
.lytGnb .gnbDepth01 .depth01List.on .depth01Name > a:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #000;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
}
.lytGnb .gnbDepth01 .depth01List.active .depth02Group {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s;
}
.lytGnb .depth02Group {
  visibility: hidden;
  opacity: 0;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 80px;
}
.lytGnb .depth02Group .depth02Area {
  width: 100%;
  /*max-width: 1384px;*/
  max-width:760px;
  padding: 24px 0;
  margin: 0 auto;
  box-sizing:border-box;
}
.lytGnb .depth02Group .depth02Box {
  display:flex;
  align-items:center;
  width: 100%;
  position: relative;
}
.lytGnb .depth02Group .depth02Box:after {
  content: "";
  display: block;
  clear: both;
}
.lytGnb .depth02Group .depth02Box .quickGroup {
  width: 250px;
  float: left;
}
.lytGnb .depth02Group .depth02Box .quickList {
  width: 100%;
}
.lytGnb .depth02Group .depth02Box .quickItem {
  width: 100%;
  height: 24px;
  margin-bottom: 19px;
  padding-left: 38px;
  text-align: left;
}
.lytGnb .depth02Group .depth02Box .quickItem a {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #000;
}
.lytGnb .depth02Group .depth02Box .quickItem:hover a {
  color: #3c89f9;
}
.lytGnb .depth02Group .depth02Box .quickItem:last-child {
  margin-bottom: 0;
}
.lytGnb .depth02Group .depth02Box .quickItem.quick01 {
  background: url(/images/icon/ico-24-gnb-01.svg) left center no-repeat;
}
.lytGnb .depth02Group .depth02Box .quickItem.quick02 {
  background: url(/images/icon/ico-24-gnb-02.svg) left center no-repeat;
}
.lytGnb .depth02Group .depth02Box .quickItem.quick03 {
  background: url(/images/icon/ico-24-gnb-03.svg) left center no-repeat;
}
.lytGnb .depth02Group .depth02Box .quickItem.quick04 {
  background: url(/images/icon/ico-24-gnb-04.svg) left center no-repeat;
}
.lytGnb .depth02Group .depth02Box .quickItem.quick01:hover {
  background: url(/images/icon/ico-24-gnb-01-on.svg) left center no-repeat;
}
.lytGnb .depth02Group .depth02Box .quickItem.quick02:hover {
  background: url(/images/icon/ico-24-gnb-02-on.svg) left center no-repeat;
}
.lytGnb .depth02Group .depth02Box .quickItem.quick03:hover {
  background: url(/images/icon/ico-24-gnb-03-on.svg) left center no-repeat;
}
.lytGnb .depth02Group .depth02Box .quickItem.quick04:hover {
  background: url(/images/icon/ico-24-gnb-04-copy.svg) left center no-repeat;
}

.lytGnb .depth02Group .depth02Box .gnbDepth02 {
  /*float: right;*/
  margin:0 0 0 auto;
}
.lytGnb .depth02Group .depth02Box .depth02List {
  padding: 0 24px;
  text-align: left;
  vertical-align: top;
  display: inline-block;
}
/* .lytGnb .depth02Group .depth02Box .depth02Name {
  margin-bottom: 45px;
} */
.lytGnb .depth02Group .depth02Box .depth02Name {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.45px;
  color: #000;
}
.lytGnb .depth02Group .depth02Box .depth03Name {
  margin-bottom: 25px;
}
.lytGnb .depth02Group .depth02Box .depth03List:last-child .depth03Name {
  margin-bottom: 0;
}
.lytGnb .depth02Group .depth02Box .depth03Name a {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #333333;
}
.lytGnb .depth02Group .depth02Box .depth03Name a:hover {
  color: #3c89f9;
}

.lytGnb .gnbQuick {
  /* float: right; */
}
.lytGnb .gnbQuick .quickMenu:after {
  content: "";
  display: block;
  clear: both;
}
.lytGnb .gnbQuick .quickMenu > li {
  width: 80px;
  height: 80px;
  position: relative;
  float: left;
}
.lytGnb .gnbQuick .quickMenu > li > button {
  width: 100%;
  height: 100%;
  font-size: 0;
}
.lytGnb .gnbQuick .quickMenu > li > button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.lytGnb .gnbQuick .quickMenu > li.on > button {
  background-color: rgba(255, 255, 255, 0.1);
}
.lytGnb .gnbQuick .gnbAllmenu .btnAllmenu {
  background-image: url(/images/icon/ico-32-menu.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.lytGnb .gnbQuick .quickSearch .btnSearch {
  background-image: url(/images/icon/ico-32-search.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.lytGnb .gnbQuick .quickAlarm .btnAlarm {
  background-image: url(/images/icon/ico-32-bell-on.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.lytGnb .gnbQuick .quickSupport .btnSupport {
  background-image: url(/images/icon/ico-32-info.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.lytGnb .gnbQuick .quickMy .btnMy {
  background-image: url(/images/icon/ico-32-profile.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.lytGnb .gnbQuick .quickSitemap .btnSitemap {
  background-image: url(/images/icon/ico-150-sitemap.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
}
.lytGnb .gnbQuick .quickMy .quickMyGroup .quickMyListGroup {
  float: right;
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}
.lytGnb .gnbQuick .quickMy .quickMyGroup .quickMyListGroup .quickMyList {
  padding: 0 44px;
  text-align: left;
  vertical-align: top;
  display: inline-block;
}
.lytGnb .quickMyGroup .quickMyListGroup .quickMyListName {
  margin-bottom: 45px;
}
.lytGnb .quickMyGroup .quickMyListGroup .quickMyListName {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.45px;
  color: #000;
}
.lytGnb .gnbQuick .quickMy.active .quickMyGroup {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s;
}

.quickMy .quickMyGroup {
  visibility: hidden;
}

.quickMyGroup .myItem {
  width: 220px;
  height: 54px;
  background-color: #fff;
  position: relative;
}

.quickMyGroup .myItem a {
  cursor: pointer;
}

.quickMyGroup .myItem > h3 {
  padding: 20px 0;
  margin: 0 20px;
  cursor: default;
  color: #646464;
  border-top: unset;
}

.quickMyGroup .myItem.myItemTitle:first-child h3 {
  padding: unset;
  border-top: unset;
  height: 54px;
  line-height: 54px;
}

.quickMyGroup .myItem.myItemTitle > h3 {
  border-top: 1px solid lightgray;
}

.quickMyGroup .myItem.duplicationList a {
  padding: unset;
  padding-left: 20px;
  width: calc(100% - 92px);
  line-height: 54px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.quickMyGroup .myItem.duplicationList.sameId a {
  cursor: default;
  pointer-events: none;
}
.quickMyGroup .myItem.duplicationList.sameId::after {
  content: "로그인 중";
  position: absolute;
  width: 72px;
  top: 50%;
  right: 20px;
  font-size: 14px;
  color: white;
  background-color: #4372c5;
  padding: 2px 8px;
  transform: translateY(-50%);
  text-align: center;
  border-radius: 40px;
}
.quickMyGroup .myItem a {
  width: 100%;
  height: 100%;
  padding: 16px 0 16px 50px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.29px;
  display: block;
}
.quickMyGroup .myItem a:hover {
  color: #3c89f9;
  background-color: #ebf3ff;
}
.quickMyGroup .myItem.duplicationList a {
  background-color: unset;
}

/*
.quickMyGroup .myItem a {
  background: url(/images/icon/ico-24-my.svg) 20px 14px no-repeat;
}
.quickMyGroup .myItem:nth-child(3) a {
  background: url(/images/icon/ico-24-learning.svg) 20px 14px no-repeat;
}

.quickMyGroup .myItem:nth-child(4) a {
  background: url(/images/icon/ico_capacityDev.svg) 20px 14px no-repeat;
}

.quickMyGroup .myItem:nth-child(5) a {
  background: url(/images/icon/ico-24-pay.svg) 20px 14px no-repeat;
}
.quickMyGroup .myItem:nth-child(6) a {
  background: url(/images/icon/ico-24-document.svg) 20px 14px no-repeat;
}
.quickMyGroup .myItem:nth-child(7) a {
  background: url(/images/icon/ico-24-favorite.svg) 20px 14px no-repeat;
}
.quickMyGroup .myItem:nth-child(8) a {
  background: url(/images/icon/ico-24-schedule.svg) 20px 14px no-repeat;
}
.quickMyGroup .myItem:nth-child(9) a {
  background: url(/images/icon/ico-24-setting.svg) 20px 14px no-repeat;
}
.quickMyGroup .myItem:nth-child(10) a {
  background: url(/images/icon/ico-24-exit.svg) 20px 14px no-repeat;
}
.quickMyGroup .myItem:nth-child(11) a {
  background: url(/images/icon/ico-24-schedule.svg) 20px 14px no-repeat;
}
.quickMyGroup .myItem:nth-child(12) a {
  background: url(/images/icon/ico-24-setting.svg) 20px 14px no-repeat;
}
.quickMyGroup .myItem:nth-child(13) a {
  background: url(/images/icon/ico-24-exit.svg) 20px 14px no-repeat;
}

.quickMyGroup .myItem:nth-child(2) a:hover {
  background-image: url(/images/icon/ico-24-my-abled.svg);
}
.quickMyGroup .myItem:nth-child(3) a:hover {
  background-image: url(/images/icon/ico-24-learning-abled.svg);
}
.quickMyGroup .myItem:nth-child(4) a:hover {
  filter:invert(50%) sepia(74%) saturate(3339%) hue-rotate(199deg) brightness(100%) contrast(96%);
}
.quickMyGroup .myItem:nth-child(5) a:hover {
  background-image: url(/images/icon/ico-24-pay-abled.svg);
}
.quickMyGroup .myItem:nth-child(6) a:hover {
  background-image: url(/images/icon/ico-24-document-abled.svg);
}
.quickMyGroup .myItem:nth-child(7) a:hover {
  background-image: url(/images/icon/ico-24-favorite-abled.svg);
}
.quickMyGroup .myItem:nth-child(8) a:hover {
  background-image: url(/images/icon/ico-24-schedule-abled.svg);
}
.quickMyGroup .myItem:nth-child(9) a:hover {
  background-image: url(/images/icon/ico-24-setting-abled.svg);
}
.quickMyGroup .myItem:nth-child(10) a:hover {
  background-image: url(/images/icon/ico-24-exit-abled.svg);
}
.quickMyGroup .myItem:nth-child(11) a:hover {
  background-image: url(/images/icon/ico-24-schedule-abled.svg);
}
.quickMyGroup .myItem:nth-child(12) a:hover {
  background-image: url(/images/icon/ico-24-setting-abled.svg);
}
.quickMyGroup .myItem:nth-child(13) a:hover {
  background-image: url(/images/icon/ico-24-exit-abled.svg);
}
*/

.quickMyGroup .myItem a[class*="iconMenu"] {display:flex; align-items:center; gap:10px; position:relative; padding:16px 18px; font-size:14px; color:#000; font-weight:500;}
.quickMyGroup .myItem a[class*="iconMenu"]:before {content:''; width:24px; height:24px; background-repeat:no-repeat; background-position:center center; background-size:100% auto;}
.quickMyGroup .myItem a[class*="iconMenu"]:hover {color:#3c89f9;}
.quickMyGroup .myItem a[class*="iconMenu"]:hover:before {filter:invert(50%) sepia(74%) saturate(3339%) hue-rotate(199deg) brightness(100%) contrast(96%);}

.quickMyGroup .myItem a.iconMenu_my:before {background-image:url('/images/icon/ico-24-my.svg');}

.quickMyGroup .myItem a.iconMenu_learning:before {background-image:url('/images/icon/ico-24-learning.svg');}

.quickMyGroup .myItem a.iconMenu_capacityDev:before {background-image:url('/images/icon/ico_capacityDev.svg');}

.quickMyGroup .myItem a.iconMenu_pay:before {background-image:url('/images/icon/ico-24-pay.svg');}

.quickMyGroup .myItem a.iconMenu_document:before {background-image:url('/images/icon/ico-24-document.svg');}

.quickMyGroup .myItem a.iconMenu_favorite:before {background-image:url('/images/icon/ico-24-favorite.svg');}

.quickMyGroup .myItem a.iconMenu_schedule:before {background-image:url('/images/icon/ico-24-schedule.svg');}

.quickMyGroup .myItem a.iconMenu_setting:before {background-image:url('/images/icon/ico-24-setting.svg');}

.quickMyGroup .myItem a.iconMenu_exit:before {background-image:url('/images/icon/ico-24-exit.svg');}



/* 파란색 테마 */
.lytGnb.blue {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #3c89f9;
}
.lytGnb.blue .gnbFixed {
  border-bottom: 0px;
  background: #3c89f9;
}
.lytGnb.blue .gnbLogo a {
  /* background: url(/images/icon/img-logo-w_2.svg) center no-repeat; */
}
.lytGnb.blue .gnbDepth01 .depth01Name a {
  color: #fff;
}
.lytGnb.blue .gnbDepth01 .depth01List a:hover:after {
  background: #fff;
}
.lytGnb.blue .gnbDepth01 .depth01List.on a:after {
  background: #fff;
}
.lytGnb.blue .gnbQuick .gnbAllmenu .btnAllmenu {
  background-image: url(/images/icon/ico-32-menu_2.svg);
}
.lytGnb.blue .gnbQuick .quickSearch .btnSearch {
  background-image: url(/images/icon/ico-32-search_2.svg);
}
.lytGnb.blue .gnbQuick .quickAlarm .btnAlarm {
  background-image: url(/images/icon/ico-32-bell-on_2.svg);
}
.lytGnb.blue .gnbQuick .quickSupport .btnSupport {
  background-image: url(/images/icon/ico-32-info-on.svg);
}
.lytGnb.blue .gnbQuick .quickMy .btnMy {
  background-image: url(/images/icon/ico-32-profile_2.svg);
}
.lytGnb.blue .gnbQuick .quickSitemap .btnSitemap {
  background-image: url(/images/icon/ico-150-sitemap_2.svg);
  background-size: 32px 32px;
}

.gnbWrap {
  display:flex;
  justify-content:flex-end;
  width: calc(100% - 220px);
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .lytGnb .gnbLogo {
    margin-left: 16px;
  }
}

@media screen and (min-width: 100px) and (max-width: 1279px) {
  .lytGnb .gnbAllmenu {
    float: right;
  }
  .lytGnb .gnbLogo {
    margin-left: 25px;
  }
  .lytGnb .gnbMenu {
    display: none;
  }
  .lytGnb .gnbQuick {
    margin-right: 0;
  }
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
  .lytGnb {
    height: 60px;
  }
  .lytGnb .gnbFixed {
    height: 60px;
  }
  .lytGnb .gnbFixed.none {
    display: none;
  }
  .lytGnb .gnbLogo {
    width: 150px;
    height: 40px;
    margin-left: 0px;
    margin: 10px 0 0 15px;
    display: block;
  }
  .lytGnb .gnbLogo a {
    background: url(/images/icon/img-logo-w_3.svg) center no-repeat;
  }
  .lytGnb .gnbMenu {
    display: none;
  }
  .lytGnb .gnbQuick {
    margin-top: 10px;
    margin-right: 0;
  }
  .lytGnb .gnbQuick .quickMenu > li {
    width: 40px;
    height: 40px;
    position: relative;
    float: left;
  }
  .lytGnb .gnbQuick .gnbAllmenu .btnAllmenu {
    background-size: 24px;
  }
  .lytGnb .gnbQuick .quickSearch .btnSearch {
    background-size: 24px;
  }
  .lytGnb .gnbQuick .quickAlarm .btnAlarm {
    background-size: 24px;
  }
  .lytGnb .gnbQuick .quickSupport .btnSupport {
    background-size: 24px;
  }

  .lytGnb.blue .gnbLogo a {
    background: unset;
  }
  .lytGnb.blue .gnbLogo a img {
    height: 40px;
  }

  .gnbWrap {
    width: calc(100% - 165px);
  }
}

/* 햄버거 메뉴 */
.lytMobileMenu {
  width: 100%;
  /* height: 100vh; */
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1100;
}
.lytMobileMenu .menuHeader {
  width: 100%;
  height: 60px;
  background: #3c89f9;
  position: relative;
}
.lytMobileMenu .headQuick {
  padding: 10px 12px;
  font-size: 0;
}
.lytMobileMenu .headQuick > .item {
  width: 40px;
  height: 40px;
  font-size: 0;
  display: inline-block;
}
.lytMobileMenu .headQuick > .item.alarm {
  background: url(/images/icon/ico-32-bell-on_2.svg) center / 24px no-repeat;
}
.lytMobileMenu .headQuick > .item.heart {
  background: url(/images/icon/ico-24-favorite-white.svg) center / 24px
  no-repeat;
}
.lytMobileMenu .headQuick > .item.headphone {
  background: url(/images/icon/ico-24-information.svg) center / 24px no-repeat;
}
.lytMobileMenu .headQuick > .item.setting {
  background: url(/images/icon/ico-24-setting-white.svg) center / 24px no-repeat;
}
.lytMobileMenu .headQuick > .item a {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.lytMobileMenu .btnClose {
  width: 60px;
  height: 60px;
  font-size: 0;
  background: url(/images/icon/ico-24-delete-white.svg) center no-repeat;
  position: absolute;
  right: 2px;
  top: 0;
}

.lytMobileMenu .menuTop {
  width: 100%;
  height: 105px;
  padding: 9px 20px 0;
  font-size: 0;
  background: #3c89f9;
}
.lytMobileMenu .menuTop .menuMessage {
  width: calc(100% - 75px);
  display: inline-block;
}
.lytMobileMenu .menuTop .menuMessage em {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: -0.58px;
  color: #fff;
  display: block;
}
.lytMobileMenu .menuTop .menuMessage i {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.21px;
  font-style: normal;
  color: #fff;
  display: block;
}
.lytMobileMenu .menuTop .menuProfile {
  width: 65px;
  height: 65px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
}

.lytMobileMenu .menuQuick {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}
.lytMobileMenu .quickGroup {
  width: 100%;
  font-size: 0;
}
.lytMobileMenu .quickGroup > .item {
  width: 25%;
  height: 104px;
  display: inline-block;
}
.lytMobileMenu .quickGroup > .item a {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.16px;
  color: #000;
  text-align: center;
  display: block;
}
.lytMobileMenu .quickGroup > .item:nth-child(1) a {
  background: url(/images/icon/ico-24-notice.svg) center 24px /32px no-repeat;
}
.lytMobileMenu .quickGroup > .item:nth-child(2) a {
  background: url(/images/icon/ico-32-study.svg) center 24px /32px no-repeat;
}
.lytMobileMenu .quickGroup > .item:nth-child(3) a {
  background: url(/images/icon/ico-32-legal.svg) center 24px /32px no-repeat;
}
.lytMobileMenu .quickGroup > .item:nth-child(4) a {
  background: url(/images/icon/ico-32-lab-black.svg) center 24px /32px no-repeat;
}

.lytMobileMenu .menuGroupOn {
  width: 100%;
  height: calc(100% - 360px);
  background: #fff;
  overflow: auto;
}
.lytMobileMenu .menuGroupOff {
  width: 100%;
  height: calc(100% - 360px);
  background: #fff;
  overflow: auto;
}
.lytMobileMenu .depth1List {
  width: 100%;
}
.lytMobileMenu .depth1Name {
  width: 100%;
  height: 73px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.lytMobileMenu .depth1List.add .depth1Name {
  background: url(/images/icon/ico-32-arrow-down.svg) calc(100% - 20px) 20px
  no-repeat;
}
.lytMobileMenu .depth1List.add.on .depth1Name {
  background: url(/images/icon/ico-32-arrow-up.svg) calc(100% - 20px) 20px
  no-repeat;
}
.lytMobileMenu .depth1Name a {
  width: 100%;
  height: 100%;
  padding: 24px 0 0 30px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  display: block;
}
.lytMobileMenu .depth2Group {
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #f2f2f2;
}
.lytMobileMenu .depth2Group.on {
  display: block;
}
.lytMobileMenu .depth2list {
  width: 100%;
}
.lytMobileMenu .depth3Name {
  width: 100%;
  height: 43px;
}
.lytMobileMenu .depth3Name a {
  width: 100%;
  height: 100%;
  padding: 12px 0 0 30px;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  display: block;
}

/* 상단 검색바 활성화 */
.lytTopSearch {
  width: 100%;
  height: calc(100vh - 80px);
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 80px;
  z-index: 1000;
  display: none;
}
.lytTopSearch.on {
  display: block;
}
.lytTopSearch .searchBar {
  width: 100%;
  height: 101px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.lytTopSearch .searchBarArea {
  width: 100%;
  height: 100%;
  max-width: 1425px;
  margin: 0 auto;
  padding: 32px 20px;
  font-size: 0;
}
.lytTopSearch .searchBtnArea {
  width: 32px;
  height: 100%;
  display: inline-block;
}
.lytTopSearch .searchBtnArea .searchBtn {
  width: 100%;
  height: 100%;
  font-size: 0;
  border: 0;
  background: url(/images/icon/ico-32-search.svg) center no-repeat;
}
.lytTopSearch .searchInputArea {
  width: calc(100% - 105px);
  display: inline-block;
  position: relative;
}
.lytTopSearch .searchInputArea .searchInput {
  width: 100%;
  height: 100%;
  font-size: 24px;
  color: #000;
  font-weight: 400;
  border: 0;
  padding-left: 30px;
  outline:none;
}
.lytTopSearch .searchInputArea .searchInput:focus {
  border-width:0 0 1px 0;
  border-style:solid;
  border-color:#757575;
}
.lytTopSearch .searchInputArea .searchInput::placeholder {
  font-weight: normal;
  color: #ddd;
}
.lytTopSearch .searchInputArea .searchInput::-webkit-input-placeholder {
  font-weight: normal;
  color: #ddd;
}
.lytTopSearch .searchInputArea .searchInput:-ms-input-placeholder {
  font-weight: normal;
  color: #ddd;
}
.lytTopSearch .searchInputArea .delText {
  width: 32px;
  height: 32px;
  font-size: 0;
  background: url(/images/icon/ico-16-delete.svg) center / 32px no-repeat;
  position: absolute;
  top: 2px;
  right: 0;
}
.lytTopSearch .closeSearch {
  width: 53px;
  height: 100%;
  margin-left: 20px;
  text-align: right;
  font-size: 18px;
  font-weight: 500;
  color: #888888;
  letter-spacing: -0.37px;
  display: inline-block;
  position: relative;
}
.lytTopSearch .closeSearch:after {
  content: "";
  width: 1px;
  height: 32px;
  background: #e5e5e5;
  display: block;
  position: absolute;
  left: 0;
  top: 7px;
}

@media screen and (min-width: 100px) and (max-width: 1279px) {
}
@media screen and (min-width: 100px) and (max-width: 1023px) {
  .lytTopSearch {
    height: calc(100vh - 60px);
    top: 60px;
  }
  .lytTopSearch .searchBar {
    height: 48px;
    border-bottom: 0;
    z-index: 999;
  }
  .lytTopSearch .searchBarArea {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    position: relative;
  }
  .lytTopSearch .searchBtnArea {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 80px;
    top: 12px;
    z-index: 2;
  }
  .lytTopSearch .searchBtnArea .searchBtn {
    background: url(/images/icon/ico-24-search.svg) center no-repeat;
    opacity: 0.4;
  }
  .lytTopSearch .searchInputArea {
    width: 100%;
    height: 100%;
  }
  .lytTopSearch .searchInputArea .searchInput {
    padding-left: 0;
    font-size: 14px;
    width: calc(100% - 30px);
  }
  .lytTopSearch .searchInputArea .delText {
    display: none;
  }
  .lytTopSearch .closeSearch {
    font-size:14px;
  }
  .lytMobileMenu .menuGroupOn {
    height: calc(100% - 260px)
  }
  .lytMobileMenu .menuGroupOff {
    height: calc(100% - 150px)
  }
}
