/* ----------------------------------------------------------------- */
/* -------------------------- 최하단 FOOTER ------------------------- */
/* ----------------------------------------------------------------- */
/* 푸터 */
.lytFooter {
  width: 100%;
  margin-top: 120px;
  background: #303030;
}
.lytFooter .footerArea {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.lytFooter .footerArea:after {
  content: "";
  display: block;
  clear: both;
}
.lytFooter .footerLogo {
  width: 36px;
  height: 32px;
  margin: 40px 0 26px 24px;
  float: left;
}
.lytFooter .footerLogo a {
  width: 100%;
  height: 100%;
  font-size: 0;
  background: url(/images/icon/img-logo-symbol.svg) center no-repeat;
  display: block;
}
.lytFooter .footerMenu {
  padding: 36px 0 0 64px;
  float: left;
}
.lytFooter .footerMenu .menuGroup {
  width: 100%;
  font-size: 0;
}
.lytFooter .footerMenu .menuList {
  display: inline-block;
  position: relative;
}
.lytFooter .footerMenu .menuList:after {
  content: "";
  width: 1px;
  height: 16px;
  background: #ffffff;
  display: block;
  position: absolute;
  right: 0;
  top: 4px;
}
.lytFooter .footerMenu .menuList:last-child:after {
  display: none;
}
.lytFooter .footerMenu .menuList a {
  padding: 0 18px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: -0.44px;
  color: #ffffff;
}
.lytFooter .footerMenu .menuList .highLight {
  font-size: 16px;
  color: #3c89f9;
}
.lytFooter .footerMenu .address {
  margin: 36px 0 0 18px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: -0.44px;
  color: #ffffff;
}
.lytFooter .footerMenu .addressMob {
  display: none;
}

.lytFooter .footerQuick {
  width: 200px;
  margin: 34px 24px 0 0;
  float: right;
}
.lytFooter .footerQuick .quickSns {
  width: 100%;
}
.lytFooter .footerQuick .quickSns:after {
  content: "";
  display: block;
  clear: both;
}
.lytFooter .footerQuick .quickSns > li {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  float: left;
}
.lytFooter .footerQuick .quickSns > li > a {
  width: 100%;
  height: 100%;
  font-size: 0;
  display: block;
}
.lytFooter .footerQuick .quickSns > li:nth-child(1) > a {
  background: url(/images/icon/ico-32-social-naver.svg) center no-repeat;
}
.lytFooter .footerQuick .quickSns > li:nth-child(2) > a {
  background: url(/images/icon/ico-32-social-youtube.svg) center no-repeat;
}
.lytFooter .footerQuick .quickSns > li:nth-child(3) > a {
  background: url(/images/icon/ico-32-social-facebook.svg) center no-repeat;
}
.lytFooter .footerQuick .quickSns > li:nth-child(4) > a {
  background: url(/images/icon/ico-32-social-insta.svg) center no-repeat;
}
.lytFooter .footerQuick .quickFamily {
  position: relative;
  display: flex;
  aling-items: center;
  justify-content: space-between;
  margin-top: 28px;
}
.lytFooter .footerQuick .quickSelect {
  width: 100%;
  height: 40px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: -0.44px;
  color: #ffffff;
  border: 0;
  background: rgba(255, 255, 255, 0.1) url(/images/icon/ico-24-arrow-down.svg)
  calc(100% - 10px) center no-repeat;
}
.lytFooter .footerQuick option {
  color: #000;
  border: 0;
}
.quickFamilyBtn {
  /* position:absolute; top:0; right:0;  */
  width: 50px; /* width:44px;  */
  height: 40px; /* height:100%;  */
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}
.lytFooter .footerCopy {
  padding: 30px 0 76px 142px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: -0.44px;
  color: #ffffff;
  float: none;
  clear: both;
}

@media screen and (min-width: 100px) and (max-width: 1279px) {
  .lytFooter .footerLogo {
    display: none;
    float: none;
  }
  .lytFooter .footerMenu {
    padding-left: 0;
  }
  .lytFooter .footerQuick {
    margin-right: 0;
  }
  .lytFooter .footerCopy {
    padding-left: 0;
  }
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
  .lytFooter {
    display: flex;
  }
  .lytFooter.view {
    display: block;
  }
  .lytFooter .footerMenu {
    padding: 30px;
    float: none;
  }
  .lytFooter .footerMenu .menuList {
    margin-bottom: 9px;
    display: block;
  }
  .lytFooter .footerMenu .menuList:last-child {
    margin-bottom: 0;
  }
  .lytFooter .footerMenu .menuList:after {
    display: none;
  }
  .lytFooter .footerMenu .menuList .highLight {
    /* color: #ffffff; */
    font-size: 12px;
    font-weight: 500;
  }
  .lytFooter .footerMenu .menuList a {
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    letter-spacing: -0.16px;
  }
  .lytFooter .footerMenu .address {
    display: none;
  }
  .lytFooter .footerMenu .addressMob {
    display: block;
    margin: 20px 0 0 0;
    font-size: 12px;
    color: #ffffff;
  }
  .lytFooter .footerQuick {
    margin: 0 0 0 20px;
    float: none;
  }
  .lytFooter .footerQuick .quickFamily {
    display: flex;
  }
  .lytFooter .footerCopy {
    padding: 15px 0 41px 20px;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: -0.25px;
    color: #7c7c7c;
  }
}

/* 모바일전용 하단 메뉴 */
.lytFooterM {
  display: none;
}
@media screen and (min-width: 100px) and (max-width: 1023px) {
  .lytFooterM {
    width: 100%;
    display: block;
  }
  .lytFooterM .footerArea {
    width: 100%;
    height: 90px;

  }
  .lytFooterM .footerMenu {
    width: 100%;
    height: 90px;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1001;
  }
  .lytFooterM .footerMenu:after {
    content: "";
    width: 134px;
    height: 5px;
    border-radius: 2.5px;
    background: #000;
    position: fixed;
    left: calc(50% - 67px);
    bottom: 9px;
  }
  .lytFooterM .footerMenu .menuGroup {
    display:flex;
    gap:4px;
    width: 100%;
    padding: 9px 5px 27px;
    font-size: 0;
  }
  .lytFooterM .footerMenu .menuList {
    /*width: 64px;*/
    width:100%;
    height: 54px;
    /*margin-left: calc(25% - 80px);*/
    font-size: 0;
    text-align: center;
    display: inline-block;
  }
  /*
  .lytFooterM .footerMenu .menuList:first-child {
    margin-left: 0;
  }
  */
  .lytFooterM .footerMenu .menuList a {
    width: 100%;
    height: 100%;
    padding-top: 34px;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: -0.16px;
    text-align: center;
    color: #2a2a2a;
    display: inline-block;
  }
  .lytFooterM .footerMenu .menuList.home a {
    background: url(/images/icon/home-1391.svg) center 8px no-repeat;
  }
  .lytFooterM .footerMenu .menuList.search a {
    background: url(/images/icon/ico-24-search.svg) center 5px no-repeat;
  }
  .lytFooterM .footerMenu .menuList.study a {
    background: url(/images/icon/book-1206.svg) center 8px no-repeat;
  }
  .lytFooterM .footerMenu .menuList.subscrip a {
    background: url(/images/icon/database-system-1797.svg) center 8px no-repeat;
  }
  .lytFooterM .footerMenu .menuList.my a {
    background: url(/images/icon/shape.svg) center 8px no-repeat;
  }
  .lytFooterM .footerMenu .menuList.capacityDev a {
    background: url(/images/icon/ico_capacityDev.svg) center 8px no-repeat;
  }
}

@media screen and (min-width:100px) and (max-width:375px) {
  .lytFooterM .footerMenu .menuGroup {
    gap:0;
  }
}